<template>
  <div class="flex flex-col w-full relative -mt-2 p-2 deails">
    <form-edit
      :value="{ fullName: roleprofile.name }"
      :controls="!roleprofile.archived"
      :action="{
        show: true,
        resource: `/organisation/unit/roleprofile/${roleprofile.id}/`,
        component: 'remove-dialog',
        title: 'Rollenprofil löschen',
        message: `Sind Sie sicher, dass Sie ${roleprofile.name} löschen wollen?`,
        event: 'reload-roleprofile',
        maxWidth: 'w-full md:w-1/4',
        type: 'delete',
      }"
      :permission="{
        module: 'Unternehmen',
        subModule: 'Organisation',
        childModule: 'Organisation',
        operation: 'edit',
        options: ['All', 'Only own personal data', 'Only own unit data'],
      }"
      @click="
        $bus.$emit('show-modal', {
          title: 'Rollenprofil bearbeiten',
          show: true,
          component: 'new-role',
          value: roleprofile,
          resource: `/organisation/unit/roleprofile/${roleprofile.id}/`,
          event: 'reload-roleprofile',
          data: roleprofile,
          maxWidth: 'w-full md:w-1/3',
          parent_id: currentNode.id,
        })
      "
    />
    <div class="w-full flex space-x-2 border bg-white rounded-sm p-2 min-h-96">
      <div class="w-full">
        <tab-card
          :data="role"
          :roleprofile="roleprofile"
          :show="true"
          @unarchive="updateRole"
          @saveData="init"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { xhr } from '@/utils/axios'
import { getOne, updateOne } from '@/services/role'

export default {
  name: 'roleprofile',
  data() {
    return {
      roleprofile: {
        id: null,
      },
      breadcrumbs: {},
    }
  },
  mounted() {
    this.$bus.$off('reload-roleprofile')
    this.$bus.$on('reload-roleprofile', this.init)

    this.breadcrumbs = {
      title: [
        {
          crumb: 'Organisation',
          action: 'reset',
          link: `/organisation/unit/${this.currentNode.id}`,
        },
      ],
      upload: {
        title: 'PDF generieren',
        event: 'roleprofile-pdf',
        global: true,
      },
      // upload: {
      //   title: 'PDF generieren',
      //   options: [
      //     {
      //       title: 'vollständig',
      //       event: 'roleprofile-pdf',
      //       param: '',
      //       global: true,
      //     },
      //     {
      //       title: 'komprimiert',
      //       event: 'roleprofile-pdf',
      //       param: '?style=min',
      //       global: true,
      //     },
      //   ],
      // },
    }
    this.$emit('breadcrumbs', this.breadcrumbs)
    this.init()
  },
  methods: {
    init() {
      this.$bus.$off('roleprofile-pdf')
      this.$bus.$on('roleprofile-pdf', this.generatePdf)

      this.id = this.$route.params.roleprofile

      getOne(this.id).then(response => {
        if (response.status === 200) {
          this.roleprofile = response.data

          const deputy_for = []

          this.roleprofile.deputy_for.forEach(element => {
            if (this.roleprofile.id !== element.id) {
              deputy_for.push({
                ...element,
                status: 'Stellvertretend',
              })
            }
          })

          const supervisor_for = []

          this.roleprofile.supervisor_for.map(element => {
            if (this.roleprofile.id !== element.id) {
              supervisor_for.push({
                ...element,
                status: 'Vorgesetzt',
              })
            }
          })

          this.roleprofile = {
            ...this.roleprofile,
            deputy_for: [...deputy_for],
            supervisor_for: [...supervisor_for],
          }
        }
      })
    },
    //   generatePdf({ param }) {
    //     xhr
    //       .get(
    //         `organisation/unit/roleprofile/pdf/${this.roleprofile.id}/${param}`,
    //         {
    //           headers: {
    //             Accept: 'application/json',
    //           },
    //           responseType: 'blob',
    //         }
    //       )
    //       .then(response => {
    //         var fileURL = window.URL.createObjectURL(new Blob([response.data]))
    //         var fileLink = document.createElement('a')

    //         fileLink.href = fileURL
    //         fileLink.setAttribute(
    //           'download',
    //           `Rollenprofil ${this.roleprofile.name}.pdf`
    //           // `roleprofile-${this.timestamp()}-${this.roleprofile.id}.pdf`
    //           // 'Rollenprofil ' + role_profile.name +'.pdf'
    //         )
    //         document.body.appendChild(fileLink)

    //         fileLink.click()
    //       })
    //   },
    //   updateRole(payload) {
    //     const roleprofile = {
    //       id: this.roleprofile.id,
    //       unit: this.currentNode.id,
    //       target_sum_workload: this.roleprofile.target_sum_workload,
    //       archived: payload,
    //     }

    //     updateOne(roleprofile).then(() => {
    //       this.roleprofile = {
    //         ...this.roleprofile,
    //         ...roleprofile,
    //       }
    //       // this.$toast.success(response.data.message)
    //     })
    //   },
    // },
    generatePdf() {
      xhr
        .get(`organisation/unit/roleprofile/pdf/${this.roleprofile.id}`, {
          headers: {
            Accept: 'application/json',
          },
          responseType: 'blob',
        })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `Rollenprofil ${this.roleprofile.name}.pdf`
            // `roleprofile-${this.timestamp()}-${this.roleprofile.id}.pdf`
            // 'Rollenprofil ' + role_profile.name +'.pdf'
          )
          document.body.appendChild(fileLink)

          fileLink.click()
        })
    },
    updateRole(payload) {
      const roleprofile = {
        id: this.roleprofile.id,
        unit: this.currentNode.id,
        target_sum_workload: this.roleprofile.target_sum_workload,
        archived: payload,
      }

      updateOne(roleprofile).then(() => {
        this.roleprofile = {
          ...this.roleprofile,
          ...roleprofile,
        }
        // this.$toast.success(response.data.message)
      })
    },
  },
  computed: {
    role() {
      return {
        tabs: [
          /** Dashboard */
          {
            caption: 'Dashboard',
            component: 'card-table',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            data: {
              roleprofile: this.roleprofile,
              data: this.roleprofile,
              title: 'Information',
              card_width: ['w-full md:w-1/2', 'w-full md:w-1/2'],
              col_width: ['w-7/12', 'w-5/12'],
              resource: '',
              cards: [
                {
                  cols: [
                    {
                      label: 'Name:',
                      value: this.roleprofile.name || '-',
                    },
                    {
                      label: 'Vorgesetzte Rolle:',
                      value: this.roleprofile.supervisor
                        ? this.roleprofile.supervisor.name
                        : '-',
                    },
                  ],
                },
                {
                  cols: [
                    {
                      label: 'Soll - Summe Arbeitspensen:',
                      value: `${this.roleprofile.target_sum_workload}%` || '-',
                    },
                    {
                      label: 'Stellvertretende Rolle:',
                      value: this.roleprofile.deputy
                        ? this.roleprofile.deputy.name
                        : '-',
                    },
                  ],
                },
              ],
              neutral: {
                label: 'Beschreibung',
                value: this.roleprofile.description || '-',
              },
              footer: {
                component: 'data-repeater',
                label: 'Referenzen',
                columns: [
                  {
                    label: 'Rolle',
                    field: ['name'],
                    link: 'id',
                    type: 'link',
                    headerClass: 'class-in-header second-class',
                    headerAlign: 'left',
                    width: '30',
                    postfix: '',
                    linkTo: 'organisation/unit/roleprofile',
                  },
                  {
                    label: 'Beziehung',
                    field: ['status'],
                    headerAlign: 'left',
                    width: '20',
                    type: 'text',
                    postfix: '',
                  },
                ],
                rows: [
                  ...(this.roleprofile.deputy_for
                    ? this.roleprofile.deputy_for
                    : []),
                  ...(this.roleprofile.supervisor_for
                    ? this.roleprofile.supervisor_for
                    : []),
                ],
              },
            },
          },
          /** Mitarbeitende */
          {
            caption: `Mitarbeitende`,
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived
              ? 'Mitarbeitende hinzufügen'
              : false,
            resource: 'personal',
            parent_id: this.roleprofile.id,
            icon: `<svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
                      />
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Mitarbeitende hinzufügen',
                component: 'new-employee',
                resource: '/organisation/unit/employe/',
                parent_id: this.roleprofile.id,
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
              })
            },
            actions: {
              index: null,
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Mitarbeitende entfernen'
                      : 'Mitarbeitende bearbeiten',
                  component:
                    state.type === 'delete' ? 'remove-dialog' : 'new-employee',
                  request: state.type,
                  message: `Möchten Sie wirklich der/die Mitarbeitende ${state.payload.personal.first_name} ${state.payload.personal.last_name} entfernen?`,
                  resource: `/organisation/unit/employe/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.employes
                ? [...this.roleprofile.employes]
                : [],
            columns: [
              {
                label: 'Mitarbeitende',
                field: ['personal.first_name', 'personal.last_name'],
                link: 'personal.id',
                type: 'link',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '30',
              },
              {
                label: 'Pensum',
                prefix: '',
                postfix: '%',
                field: ['workload'],
                headerAlign: 'left',
                width: '20',
              },
              {
                label: 'Pensum Home Office',
                prefix: '',
                postfix: '%',
                field: ['workload_home_office'],
                headerAlign: 'left',
                width: '30',
              },
              {
                label: 'Startdatum',
                field: ['start_date'],
                headerAlign: 'left',
                width: '20',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Anforderungen */
          {
            caption: 'Anforderungen',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived
              ? 'Anforderung erstellen'
              : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                      <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Anforderung erstellen',
                component: 'new-requirement',
                resource: '/organisation/unit/requirement/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Anforderung entfernen'
                      : 'Anforderung bearbeiten',
                  component:
                    state.type === 'delete'
                      ? 'remove-dialog'
                      : 'new-requirement',
                  request: state.type,
                  message: `Möchten Sie wirklich den Anforderungen ${state.payload.name} entfernen?`,
                  resource: `/organisation/unit/requirement/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.requirements
                ? [...this.roleprofile.requirements]
                : [],
            columns: [
              {
                label: 'Bezeichnung',
                prefix: '',
                postfix: '',
                field: ['name'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '30',
              },
              {
                label: 'Beschreibung',
                prefix: '',
                postfix: '',
                field: ['description'],
                headerAlign: 'left',
                width: '30',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Aufgaben */
          {
            caption: 'Aufgaben',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived ? 'Aufgabe erstellen' : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 30 30"><path d="M 3 5 L 3 9 L 7 9 L 7 5 L 3 5 z M 12 6 A 1.0001 1.0001 0 1 0 12 8 L 26 8 A 1.0001 1.0001 0 1 0 26 6 L 12 6 z M 3 13 L 3 17 L 7 17 L 7 13 L 3 13 z M 12 14 A 1.0001 1.0001 0 1 0 12 16 L 26 16 A 1.0001 1.0001 0 1 0 26 14 L 12 14 z M 3 21 L 3 25 L 7 25 L 7 21 L 3 21 z M 12 22 A 1.0001 1.0001 0 1 0 12 24 L 26 24 A 1.0001 1.0001 0 1 0 26 22 L 12 22 z"></path></svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Aufgabe erstellen',
                component: 'new-task',
                resource: '/organisation/unit/task/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Aufgaben entfernen'
                      : 'Aufgaben bearbeiten',
                  component:
                    state.type === 'delete' ? 'remove-dialog' : 'new-task',
                  request: state.type,
                  message: `Möchten Sie wirklich den Aufgaben ${state.payload.name} entfernen?`,
                  resource: `/organisation/unit/task/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.tasks
                ? [...this.roleprofile.tasks]
                : [],
            columns: [
              {
                label: 'Kategorie',
                prefix: '',
                postfix: '',
                field: ['categorie'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Aufgabe',
                prefix: '',
                postfix: '',
                field: ['name'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '30',
              },
              {
                label: 'Beschreibung',
                prefix: '',
                postfix: '',
                field: ['description'],
                headerAlign: 'left',
                width: '30',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Persönlichkeitskompetenzen */
          {
            caption: 'Persönlichkeitskompetenzen',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived
              ? 'Persönlichkeitskompetenzen erstellen'
              : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M12,9c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C10,9.895,10.895,9,12,9z M16,17H8v-1c0-1.33,2.665-2,4-2s4,0.67,4,2V17z"></path>
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Persönlichkeitskompetenzen erstellen',
                component: 'new-skill',
                resource: '/organisation/unit/skill/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Persönlichkeitskompetenzen entfernen'
                      : 'Persönlichkeitskompetenzen bearbeiten',
                  component:
                    state.type === 'delete' ? 'remove-dialog' : 'new-skill',
                  request: state.type,
                  message: `Möchten Sie wirklich den Persönlichkeitskompetenzen ${state.payload.personalityskilldata.name} entfernen?`,
                  resource: `/organisation/unit/skill/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.personalityskills
                ? [...this.roleprofile.personalityskills]
                : [],
            columns: [
              {
                label: 'Persönlichkeitskompetenzen',
                prefix: '',
                postfix: '',
                field: ['personalityskilldata.name'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Beschreibung',
                prefix: '',
                postfix: '',
                field: ['personalityskilldata.description'],
                headerAlign: 'left',
                width: '40',
                strlen: '150',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Kompetenz */
          {
            caption: 'Kompetenzen',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived ? 'Kompetenz erstellen' : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M12,9c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C10,9.895,10.895,9,12,9z M16,17H8v-1c0-1.33,2.665-2,4-2s4,0.67,4,2V17z"></path>
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Kompetenz erstellen',
                component: 'new-competence',
                resource: '/organisation/unit/roleprofile/competence/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Kompetenz entfernen'
                      : 'Kompetenz bearbeiten',
                  component:
                    state.type === 'delete'
                      ? 'remove-dialog'
                      : 'new-competence',
                  request: state.type,
                  message: `Möchten Sie wirklich den Kompetenz ${state.payload.competence} entfernen?`,
                  resource: `/organisation/unit/roleprofile/competence/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.competences
                ? [...this.roleprofile.competences]
                : [],
            columns: [
              {
                label: 'Kategorie',
                prefix: '',
                postfix: '',
                field: ['categorie'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Kompetenz',
                prefix: '',
                postfix: '',
                field: ['competence'],
                headerAlign: 'left',
                width: '40',
                strlen: '150',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Obligation */
          {
            caption: 'Pflichten',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived ? 'Pflicht erstellen' : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M12,9c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C10,9.895,10.895,9,12,9z M16,17H8v-1c0-1.33,2.665-2,4-2s4,0.67,4,2V17z"></path>
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Pflicht erstellen',
                component: 'new-obligation',
                resource: '/organisation/unit/roleprofile/obligation/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Pflicht entfernen'
                      : 'Pflicht bearbeiten',
                  component:
                    state.type === 'delete'
                      ? 'remove-dialog'
                      : 'new-obligation',
                  request: state.type,
                  message: `Möchten Sie wirklich die Pflicht ${state.payload.obligation} entfernen?`,
                  resource: `/organisation/unit/roleprofile/obligation/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.obligations
                ? [...this.roleprofile.obligations]
                : [],
            columns: [
              {
                label: 'Kategorie',
                prefix: '',
                postfix: '',
                field: ['categorie'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Pflicht',
                prefix: '',
                postfix: '',
                field: ['obligation'],
                headerAlign: 'left',
                width: '40',
                strlen: '150',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Document */
          {
            caption: 'Dokumente',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived ? 'Dokument erstellen' : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M12,9c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C10,9.895,10.895,9,12,9z M16,17H8v-1c0-1.33,2.665-2,4-2s4,0.67,4,2V17z"></path>
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Dokument erstellen',
                component: 'new-document',
                resource: '/organisation/unit/roleprofile/document/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Dokument entfernen'
                      : 'Dokument bearbeiten',
                  component:
                    state.type === 'delete' ? 'remove-dialog' : 'new-document',
                  request: state.type,
                  message: `Möchten Sie wirklich das Dokument ${state.payload.name} entfernen?`,
                  resource: `/organisation/unit/roleprofile/document/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.documents
                ? [...this.roleprofile.documents]
                : [],
            columns: [
              {
                label: 'Kategorie',
                prefix: '',
                postfix: '',
                field: ['categorie'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Name',
                prefix: '',
                postfix: '',
                field: ['name'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Link',
                prefix: '',
                postfix: '',
                field: 'link',
                link: "link",
                type: 'preview',
                headerAlign: 'left',
                width: '40',
                strlen: '150',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Input */
          {
            caption: 'Input',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived ? 'Input erstellen' : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M12,9c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C10,9.895,10.895,9,12,9z M16,17H8v-1c0-1.33,2.665-2,4-2s4,0.67,4,2V17z"></path>
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Input erstellen',
                component: 'new-input',
                resource: '/organisation/unit/roleprofile/input/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Input entfernen'
                      : 'Input bearbeiten',
                  component:
                    state.type === 'delete' ? 'remove-dialog' : 'new-input',
                  request: state.type,
                  message: `Möchten Sie wirklich den Input ${state.payload.input} entfernen?`,
                  resource: `/organisation/unit/roleprofile/input/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.inputs
                ? [...this.roleprofile.inputs]
                : [],
            columns: [
              {
                label: 'Kategorie',
                prefix: '',
                postfix: '',
                field: ['categorie'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Input',
                prefix: '',
                postfix: '',
                field: ['input'],
                headerAlign: 'left',
                width: '40',
                strlen: '150',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Interface */
          {
            caption: 'Schnittstellen',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived
              ? 'Schnittstelle erstellen'
              : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M12,9c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C10,9.895,10.895,9,12,9z M16,17H8v-1c0-1.33,2.665-2,4-2s4,0.67,4,2V17z"></path>
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Schnittstelle erstellen',
                component: 'new-interfac',
                resource: '/organisation/unit/roleprofile/interface/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Schnittstelle entfernen'
                      : 'Schnittstelle bearbeiten',
                  component:
                    state.type === 'delete' ? 'remove-dialog' : 'new-interfac',
                  request: state.type,
                  message: `Möchten Sie wirklich die Schnittstelle ${state.payload.interface} entfernen?`,
                  resource: `/organisation/unit/roleprofile/interface/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.interfaces
                ? [...this.roleprofile.interfaces]
                : [],
            columns: [
              {
                label: 'Kategorie',
                prefix: '',
                postfix: '',
                field: ['categorie'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Schnittstelle',
                prefix: '',
                postfix: '',
                field: ['interface'],
                headerAlign: 'left',
                width: '40',
                strlen: '150',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
          /** Output */
          {
            caption: 'Output',
            component: 'data-repeater',
            permission: {
              module: 'Unternehmen',
              subModule: 'Organisation',
              childModule: 'Organisation',
              operation: 'edit',
              options: ['All'],
            },
            addBtn: !this.roleprofile.archived ? 'Output erstellen' : false,
            resource: '',
            parent_id: this.roleprofile.id,
            icon: `<svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M12,9c1.105,0,2,0.895,2,2 c0,1.105-0.895,2-2,2s-2-0.895-2-2C10,9.895,10.895,9,12,9z M16,17H8v-1c0-1.33,2.665-2,4-2s4,0.67,4,2V17z"></path>
                    </svg>`,
            callback: () => {
              this.$bus.$emit('show-modal', {
                show: true,
                title: 'Output erstellen',
                component: 'new-output',
                resource: '/organisation/unit/roleprofile/output/',
                maxWidth: 'w-full md:w-1/3',
                event: 'reload-roleprofile',
                parent_id: this.roleprofile.id,
              })
            },
            actions: {
              list: ['edit', 'delete'],
              callback: state => {
                this.$bus.$emit('show-modal', {
                  show: true,
                  title:
                    state.type === 'delete'
                      ? 'Output entfernen'
                      : 'Output bearbeiten',
                  component:
                    state.type === 'delete' ? 'remove-dialog' : 'new-output',
                  request: state.type,
                  message: `Möchten Sie wirklich den Output ${state.payload.output} entfernen?`,
                  resource: `/organisation/unit/roleprofile/output/${state.payload.id}/`,
                  data: state.payload,
                  parent_id: this.roleprofile.id,
                  maxWidth: 'w-full md:w-1/3',
                  event: 'reload-roleprofile',
                  type: state.type,
                })
              },
            },
            rows:
              this.roleprofile && this.roleprofile.outputs
                ? [...this.roleprofile.outputs]
                : [],
            columns: [
              {
                label: 'Kategorie',
                prefix: '',
                postfix: '',
                field: ['categorie'],
                type: 'text',
                headerClass: 'class-in-header second-class',
                headerAlign: 'left',
                width: '25',
              },
              {
                label: 'Output',
                prefix: '',
                postfix: '',
                field: ['output'],
                headerAlign: 'left',
                width: '40',
                strlen: '150',
              },
              { label: '', field: ['id'], type: 'checkbox', width: '10' },
            ],
          },
        ],
      }
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
}
</script>

<style>
@page {
  margin: 2mm;
}
.header,
.header-space,
.footer,
.footer-space {
  height: 100px;
}
.header {
  position: fixed;
  top: 0;
}
.footer {
  position: fixed;
  bottom: 0;
}
</style>
