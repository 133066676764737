var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full relative -mt-2 p-2 deails"},[_c('form-edit',{attrs:{"value":{ fullName: _vm.roleprofile.name },"controls":!_vm.roleprofile.archived,"action":{
      show: true,
      resource: ("/organisation/unit/roleprofile/" + (_vm.roleprofile.id) + "/"),
      component: 'remove-dialog',
      title: 'Rollenprofil löschen',
      message: ("Sind Sie sicher, dass Sie " + (_vm.roleprofile.name) + " löschen wollen?"),
      event: 'reload-roleprofile',
      maxWidth: 'w-full md:w-1/4',
      type: 'delete',
    },"permission":{
      module: 'Unternehmen',
      subModule: 'Organisation',
      childModule: 'Organisation',
      operation: 'edit',
      options: ['All', 'Only own personal data', 'Only own unit data'],
    }},on:{"click":function($event){return _vm.$bus.$emit('show-modal', {
        title: 'Rollenprofil bearbeiten',
        show: true,
        component: 'new-role',
        value: _vm.roleprofile,
        resource: ("/organisation/unit/roleprofile/" + (_vm.roleprofile.id) + "/"),
        event: 'reload-roleprofile',
        data: _vm.roleprofile,
        maxWidth: 'w-full md:w-1/3',
        parent_id: _vm.currentNode.id,
      })}}}),_c('div',{staticClass:"w-full flex space-x-2 border bg-white rounded-sm p-2 min-h-96"},[_c('div',{staticClass:"w-full"},[_c('tab-card',{attrs:{"data":_vm.role,"roleprofile":_vm.roleprofile,"show":true},on:{"unarchive":_vm.updateRole,"saveData":_vm.init}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }